import { Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useIntl } from "gatsby-plugin-intl"

const StyledSection = styled.section`
  position: relative;
  max-width: 1500px;
  margin: 150px auto 0;
  padding: 0 20px 100px;
`

const StyledTypography = styled(Typography)`
  text-align: left;
  margin: 40px 0 20px;
  font-weight: 600;
  font-size: 2.5rem;
  @media only screen and (min-width: 1024px) {
    font-size: 3rem;
  }
`

const StyledSubtitle = styled(Typography)`
  text-align: left;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 1.2rem;

  @media only screen and (min-width: 1024px) {
    font-size: 1.8rem;
  }
`
const StyledDescription = styled(Typography)`
  max-width: 400px;
  font-size: 0.9rem;
  @media only screen and (min-width: 1024px) {
    font-size: 1.1rem;
  }
`

const Img = styled(Image)`
  width: 100%;
  max-width: 600px;
  min-width: 320px;
  /* padding-left: 10px; */
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;
  padding-right: 20px;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    margin-bottom: 80px;
  }

  .text-wrapper {
    width: 100%;

    @media only screen and (min-width: 1024px) {
      width: 40%;
    }
  }

  .img-wrapper {
    width: 100%;
    @media only screen and (min-width: 1024px) {
      margin-left: 10px;
      width: 50%;
    }
  }
`

const WhereWeFly = () => {
  const { locale, formatMessage } = useIntl()
  const data = useStaticQuery(graphql`
    {
      silesia: file(name: { eq: "silesia" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      jura: file(name: { eq: "jura" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tatry: file(name: { eq: "tatry" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mazury: file(name: { eq: "mazury" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      warsaw: file(name: { eq: "warsaw" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      cracow: file(name: { eq: "cracow" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const places = [
    {
      title: formatMessage({ id: "Over Silesia" }),
      subtitle: formatMessage({ id: "Silesia description" }),
      img: data.silesia.childImageSharp.fluid,
    },
    {
      title: formatMessage({ id: "Over Krakow-Czestochowa Region" }),
      subtitle: formatMessage({ id: "Krakow-Czestochowa Region description" }),
      img: data.jura.childImageSharp.fluid,
    },
    {
      title: formatMessage({ id: "Over Krakow" }),
      subtitle: formatMessage({ id: "Krakow description" }),
      img: data.cracow.childImageSharp.fluid,
    },
    {
      title: formatMessage({ id: "Over the Tatra Mountains" }),
      subtitle: formatMessage({ id: "Tatra Mountains description" }),
      img: data.tatry.childImageSharp.fluid,
    },
    {
      title: formatMessage({ id: "Over Masurian Lake District" }),
      subtitle: formatMessage({ id: "Masurian Lake District description" }),
      img: data.mazury.childImageSharp.fluid,
    },
    {
      title: formatMessage({ id: "Over Warsaw" }),
      subtitle: formatMessage({ id: "Warsaw description" }),
      img: data.warsaw.childImageSharp.fluid,
    },
  ]
  return (
    <StyledSection>
      <StyledTypography>
        <strong>{formatMessage({ id: "Where do we fly" })}</strong>
      </StyledTypography>
      <p style={{ maxWidth: 800, width: "80%", marginBottom: 80 }}>
        {formatMessage({ id: "Where do we fly description 1" })}{" "}
        <AniLink paintDrip duration={1} hex="#252a36" to={`/${locale}/contact`}>
          {formatMessage({ id: "Where do we fly description 2" })}
        </AniLink>
        .
      </p>

      {places.map(item => (
        <Card>
          <div className="text-wrapper">
            <StyledSubtitle>
              <strong>{item.title}</strong>
            </StyledSubtitle>
            <StyledDescription>{item.subtitle}</StyledDescription>
          </div>

          <div className="img-wrapper">
            <Img fluid={item.img} objectFit="0% 50%" />
          </div>
        </Card>
      ))}
    </StyledSection>
  )
}

export default WhereWeFly
